import Media from 'components/Media';
import Footnotes from 'components/Footnotes';
import RichText from 'components/RichText';
import classNames from 'classnames';

const Compliance1a = ({ settings, description, media, footnotes, meta }) => {
    return (
        <section
            className={classNames('section', {
                ...settings,
            })}
            {...meta}
        >
            <div className="container">
                <div className="compliance">
                    {media && (
                        <>
                            <Media
                                wrapperClassName="compliance__image"
                                media={media}
                                disableCaption
                            />
                        </>
                    )}
                    {description && (
                        <div className="compliance__content">
                            <RichText
                                className="compliance__description"
                                data={description}
                            />

                            {footnotes?.value && (
                                <Footnotes
                                    className={'mt-4'}
                                    footnotes={footnotes}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Compliance1a;
