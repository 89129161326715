/**
 * Compliance Block
 */

import Compliance1a from './variants/Compliance1a';

const prefix = 'compliance';

const Compliance = ({ ...block }) => {
	switch (block.variant) {
		case `${prefix}_1a`:
			return <Compliance1a {...block} />;
		default:
			return null;
	}
};

export default Compliance;
